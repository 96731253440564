import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServiceCardTwo from "@/components/service-card-two";

const ServiceTwo = (data) => {

  const prorgamsWeUse = data.data.strapiProgramsWeUseSection;
  const progs = prorgamsWeUse.programsweuses;
  progs.sort((a, b) => { return (a.sort - b.sort) } )


  return (
    <section className="service_section commonSection">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="sec_title white">{prorgamsWeUse.title}</h2>
            <div className={"maxWidthService"}>
              <p className="sec_desc color_aaa">{prorgamsWeUse.description}</p>
            </div>
          </Col>
        </Row>
        <Row lg={10} className={"serviceFlex"}>

          {progs.map((data, index) => (
            <Col key={index} lg={2} md={4} sm={6}>
              <ServiceCardTwo data={data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServiceTwo;
