import React from "react";

const ServiceCardTwo = ({ data }) => {

  const title = data.title;
  const description = data.description;
  const icon = data.icon.localFile.publicURL;
  const padding = data.padding
  const hasPadding = data.padding && data.padding !== 0;

  let style = {};

  if (hasPadding) {
    style = {width: padding/2}
  }

  return (
    <div className="icon_box_1 text-center"  >
        <img src={icon} alt={description} style={style}></img>
    </div>
  );
};

export default ServiceCardTwo;
