import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Parallax} from "react-parallax";
import backgroundImg from "../assets/images/bg/spark/6.jpg"

const ParallaxOne = (data) => {


    const services = data.data.strapiServicedescription;

    return (
        <Parallax strength={400} bgImage={backgroundImg}>
            <section id="services" className="commonSection">
                <Container>
                    <Row>
                        <Col lg={{span: 10}} sm={12} className="text-center">
                            <div className="ourservices">


                                <div><h2 className={"sec_title white"}>{services.title}</h2></div>
                                <div><p>{services.description}</p></div>
                                <div className={"servicelist"}>
                                    {services.service_features.map((it, index) => {
                                        return <div key={index} className={"servicefeature"}>
                                            <i className="fa fa-check-square"></i><h3>{it.service}</h3>
                                        </div>
                                    })}
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Parallax>
    );
};

export default ParallaxOne;
