import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import backgroundImg from "../assets/images/bg/spark/6.jpg"
import {Parallax} from "react-parallax";

const ParallaxTwo = (data) => {


//  strapiNeemustory {
//     description {
//       data {
//         id
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }

    return (
        <Parallax strength={400} bgImage={backgroundImg}>

            <section className="commonSection testimonial2">
                <Container>
                    <Row>
                        <Col lg={{span: 10, offset: 1}} sm={12} className="text-center">
                            <div className={"testimonalHTMLWrapper"}
                                 dangerouslySetInnerHTML={{
                                     __html: data.data.strapiNeemustory.description.data.childMarkdownRemark.html,
                                 }}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Parallax>
    );
};

export default ParallaxTwo;
