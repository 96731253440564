import React from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "../swiper/swiper-gl.scss"

import Layout from "@/components/layout";
import Footer from "@/components/footer";
import ParallaxOne from "@/components/parallax-1";
import ParallaxTwo from "@/components/parallax-2";
import PortfolioHome from "@/components/portfolio-home";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

import SliderTwo from "@/components/slider-two";
import ServiceTwo from "@/components/service-two";
import ContactForm from "../components/contact-form";
import {graphql} from "gatsby"
import HeaderOne from "../components/header-one";
import SEO from "../components/seo";
import FeatureTwo from "../components/feature-two";
import FeatureTabOne from "../components/feature-tab-1";
import HomeSlider from "../components/home-slider";
import ClientCarouselOne from "../components/client-carousel-one";
import ParallaxClients from "../components/parallax-clients";
import HomePageVideo from "../components/home-page-video";

const HomeTwo = (data) => {
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <SEO title="Neemu - VFX Studio"/>
                <Layout>
                    <HeaderOne/>
                    {/*<SliderTwo {...data}/>*/}
                    <HomeSlider {...data}></HomeSlider>


                    <ParallaxClients {...data}></ParallaxClients>
                    <FeatureTabOne {...data}></FeatureTabOne>

                    <HomePageVideo {...data}/>

                    <ParallaxOne {...data}/>

                    <ServiceTwo {...data} />

                    <ParallaxTwo {...data} />


                    <ContactForm/>

                    <Footer/>
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default HomeTwo;


export const query = graphql`
query Hero {
  allStrapiHero {
    nodes {
      heading
      description
      video {
        mime
        localFile { 
          publicURL
        }
      }
    }
  }
  allStrapiPortfolioEntry {
    nodes {
        title
        description
        coverimage {
            localFile {
                publicURL
            }
        }
        video {
            mime
            localFile {
                publicURL
            }
        }
    }
  }
  
  strapiServicedescription {
    description
    title
    service_features {
      service
    }
  }
  
  strapiProgramsWeUseSection {
    title
    description
    programsweuses {
      title
      description
      sort
      padding
      icon {
        localFile {
          publicURL
        }
      }
    }
  }
  
  strapiNeemustory {
    description {
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
  }
  
}
`
