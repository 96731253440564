import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import {SliderThreeData} from "../data";


//Meow swiper
import {Swiper, SwiperSlide} from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const HomeSlider = (data) => {

  return (
    <section id={"home"} className="main-slider main-slider__two baldursCarousel hero">

      <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          effect="slide"
          speed={1000}
          spaceBetween={0}
          loop={true}
          centeredSlides={true}
          autoplay={{
              delay: 5000,
              disableOnInteraction: false,
          }}
          pagination={{
              clickable: true,
          }}
          navigation={true}
          className="mySwiper"

      >
          {SliderThreeData.map(({image, subTitle, title, button}, index) => (
              <SwiperSlide key={index}>
                  <div className={"baldursCarouselContainer"}>
                      <img alt="img" src={`${image}`}></img>
                  </div>
              </SwiperSlide>
          ))}

      </Swiper>

    </section>
  );
};


export default HomeSlider;
