import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import backgroundImg from "../assets/images/bg/spark/6.jpg"
import {Parallax} from "react-parallax";
import ClientCarouselOne from "./client-carousel-one";

const ParallaxClients = (data) => {


//  strapiNeemustory {
//     description {
//       data {
//         id
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }

    return (
        <Parallax strength={400} bgImage={backgroundImg}>
            <ClientCarouselOne></ClientCarouselOne>
        </Parallax>
    );
};

export default ParallaxClients;
